import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class CashReceiptProvider extends HttpRequest {
  getCashReceipts (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/cash-receipts', query)
  }

  createCashReceipt (payload) {
    this.setHeader(getAuthToken())
    return this.post('/cash-receipts', payload)
  }
}

export default CashReceiptProvider
