<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-form
      ref="order-form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-row justify-space-between align-center">
          <h2>
            จัดการ Cash Receipt
          </h2>
        </v-col>
        <v-col
          cols="12"
          md="4">
          <date-picker
            v-model="formData.postingDate"
            label="วันที่เอกสาร"
            :rules="textBoxRules"
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="4">
          <v-text-field
            v-model.trim="formData.accountNumber"
            label="รหัสบัญชี Suspense CN"
            :rules="textBoxRules"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="4">
          <v-text-field
            v-model.trim="formData.balAccountNumber"
            label="รหัสบัญชี รายได้อื่น"
            color="secondary"
            :rules="textBoxRules"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="8">
          <v-text-field
            v-model.trim="formData.description"
            label="รายละเอียดการทำรายการ"
            color="secondary"
            :rules="textBoxRules"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="4">
          <v-text-field
            v-model.number="formData.amount"
            :rules="numberRules"
            label="จำนวนเงิน"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center align-center"
          style="gap: 8px;">
          <v-btn
            class="px-8"
            color="success"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import CashReceiptProvider from '@/resources/CashReceiptProvider'
import DatePicker from '../components/DatePicker.vue'

const CashReceiptService = new CashReceiptProvider()

export default {
  components: { DatePicker },
  data () {
    return {
      loading: false,
      textBoxRules: [
        (v) => !!v || 'Field is require.'
      ],
      numberRules: [
        (v) => !!v || 'Field is require.',
        (v) => v > 0 || 'This field must be more than 0.'
      ],
      formData: {
        postingDate: null,
        accountNumber: '201308',
        amount: 0,
        description: '',
        balAccountNumber: '405101'
      }
    }
  },
  computed: {
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async onSubmit () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        await CashReceiptService.createCashReceipt(this.formData)
        this.setSnackbar({
          value: true,
          message: 'Create Success',
          type: 'success'
        })

        this.$router.push({ name: 'CashReceipt' })
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
